<template>
    <div class="dataTables_date text-center position-relative dropdown-center my-4 my-lg-0">
        <label data-bs-toggle="dropdown">
            <input class="text-center" :value="date" readonly role="button">
        </label>
        <ul class="dropdown-menu dropdowns">
            <li @click="filterByDate('last30Days')">
                <span class="dropdown-item">
                    {{ $t('DataTable.date_filter.last30Days') }}
                </span>
            </li>
            <li @click="filterByDate('thisMonth')">
                <span class="dropdown-item">
                    {{ $t('DataTable.date_filter.thisMonth') }}
                </span>
            </li>
            <li @click="filterByDate('lastMonth')">
                <span class="dropdown-item">
                    {{ $t('DataTable.date_filter.lastMonth') }}
                </span>
            </li>
            <li @click="filterByDate('last12Months')">
                <span class="dropdown-item">
                    {{ $t('DataTable.date_filter.last12Months') }}
                </span>
            </li>
            <li @click="filterByDate('custom')">
                <span class="dropdown-item">
                    {{ $t('DataTable.date_filter.custom') }}
                </span>
            </li>
        </ul>
        <v-date-picker class="calendar position-absolute top-100 d-none" v-model.range="range" :attributes="attrs" trim-weeks :max-date="attrs[0].dates" :locale="this.$i18n.locale" @dayclick="setRange"/>
    </div>
</template>

<script>
import $ from 'jquery'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
    name: 'DateRanges',
    props: {
        date: String
    },
    emits: ['filterByDate'],
    computed: {
        ...mapState(['config'])
    },
    data() {
        return {
            range: {
                start: null,
                end: null,
            },
            attrs: [
                {
                    key: 'today',
                    dot: true,
                    dates: moment().format(),
                },
                {
                    highlight: {
                        color: 'blue',
                        fillMode: 'light',
                    },
                    dates: '',
                },
            ],
        }
    }, 
    methods: {
        setRange() {
            if (this.range.start && this.range.end) {
                this.$emit('filterByDate', {
                    ECstartDate: moment(this.range.start).format('YYYY-MM-DD-HH:mm'),
                    ECendDate: moment(this.range.end).format('YYYY-MM-DD-HH:mm'),
                    startDate: moment(this.range.start).format('YYYY-MM-DD'),
                    endDate: moment(this.range.end).format('YYYY-MM-DD')
                })
            }
        },
        filterByDate(when) {
            switch (when) {
                case 'last30Days':
                    this.$emit('filterByDate', {
                        ECstartDate: moment().subtract(29, 'days').format('YYYY-MM-DD-HH:mm'),
                        ECendDate: moment().format('YYYY-MM-DD-HH:mm'),
                        startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
                        endDate: moment().format('YYYY-MM-DD')
                    })
                    break;
                case 'thisMonth':
                    this.$emit('filterByDate', {
                        ECstartDate: moment().startOf('month').format('YYYY-MM-DD-HH:mm'),
                        ECendDate: moment().endOf('month').format('YYYY-MM-DD-HH:mm'),
                        startDate: moment().startOf('month').format('YYYY-MM-DD'),
                        endDate: moment().endOf('month').format('YYYY-MM-DD')
                    })
                    break;
                case 'lastMonth':
                    this.$emit('filterByDate', {
                        ECstartDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD-HH:mm'),
                        ECendDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD-HH:mm'),
                        startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                        endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                    })
                    break;
                case 'last12Months':
                    this.$emit('filterByDate', {
                        ECstartDate: moment().subtract(12, 'months').format('YYYY-MM-DD-HH:mm'),
                        ECendDate: moment().format('YYYY-MM-DD-HH:mm'),
                        startDate: moment().subtract(12, 'months').format('YYYY-MM-DD'),
                        endDate: moment().format('YYYY-MM-DD')
                    })
                    break;
                case 'custom':
                    $('.calendar').removeClass('d-none')
                    break;
            }
        },
    }
}
</script>