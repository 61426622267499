<template>
    <form name="frm" id="frm" method="post" class="sia_payments" :action="config.tokenizer.SIA.ENDPOINT" v-if="SIARequest">
        <input type="hidden" name="AMOUNT" :value="SIARequest.AMOUNT">
        <input type="hidden" name="EMAIL" :value="SIARequest.EMAIL">
        <input type="hidden" name="SHOPEMAIL" :value="SIARequest.SHOPEMAIL">
        <input type="hidden" name="ORDDESCR" :value="SIARequest.ORDDESCR">
        <input type="hidden" name="CURRENCY" :value="config.tokenizer.SIA.CURRENCY">
        <input type="hidden" name="SHOPID" :value="config.tokenizer.SIA.SHOPID_PAY">
        <input type="hidden" name="ORDERID" :value="SIARequest.ORDERID">
        <input type="hidden" name="OPTIONS" value="V">
        <input type="hidden" name="URLDONE" :value="SIARequest.URLDONE">
        <input type="hidden" name="URLMS" :value="config.tokenizer.SIA.URLMS_PAY">
        <input type="hidden" name="URLBACK" :value="config.tokenizer.SIA.URLBACK_PAY">
        <input type="hidden" name="ACCOUNTINGMODE" :value="config.tokenizer.SIA.ACCOUNTINGMODE_PAY">
        <input type="hidden" name="AUTHORMODE" :value="config.tokenizer.SIA.AUTHORMODE">
        <input type="hidden" name="MAC" :value="SIARequest.MAC">
        <input type="hidden" name="REQ" :value="SIARequest.request">
        <button type="submit" @click="PendingPaymentProcessing" class="btn">
            {{ $t('Dashboard.Payments.Modals.PendingPayments.pay_now') }}
        </button>
    </form>
</template>

<script>
const { v4: uuidv4 } = require('uuid')
import crypto from 'crypto-js'
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
export default {
    name: 'SIAPayments',
    computed: {
        ...mapState(['config', 'account']),
        ...mapGetters(['apiPath'])
    },
    props: {
        short_id: String,
        alias_pan: String,
        ECdates: Object
    },
    data() {
        return {
            SIARequest: null
        }
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        async getSIARequest() {
            const total_pending_payments_amount = await this.getEasyChannelRequest()
            if (total_pending_payments_amount === 0) {
                return
            }
            var siaRequest = {
                AMOUNT: total_pending_payments_amount,
                URLDONE: this.config.tokenizer.SIA.URLDONE_PAY,
                ORDERID: uuidv4(),
                EMAIL: this.account.email,
                SHOPEMAIL: this.config.tokenizer.SIA.SHOPEMAIL,
                request : null,
                MAC: null
            }

            var request = `URLMS=${this.config.tokenizer.SIA.URLMS_PAY}&URLDONE=${siaRequest.URLDONE}&ORDERID=${siaRequest.ORDERID}&SHOPID=${this.config.tokenizer.SIA.SHOPID_PAY}&AMOUNT=${siaRequest.AMOUNT}&CURRENCY=${this.config.tokenizer.SIA.CURRENCY}&ACCOUNTINGMODE=${this.config.tokenizer.SIA.ACCOUNTINGMODE_PAY}&AUTHORMODE=${this.config.tokenizer.SIA.AUTHORMODE}&OPTIONS=V`
            
            siaRequest.request = request

            var hash = crypto.HmacSHA256(request, this.config.tokenizer.SIA.APIKEY_BEGIN_PAY)
            var MAC = crypto.enc.Hex.stringify(hash)
            siaRequest.MAC = MAC

            return siaRequest
        },
        async getEasyChannelRequest() {
            var total_pending_payments_amount = 0
            var EasyChannelOptions = {
                currentTimeStamp: moment().format('YYYYMMDDHHmmssSSS'),
                AbiMittente: this.config.tokenizer.SIA.EasyChannel.AbiMittente,
                AbiOrdinante: this.config.tokenizer.SIA.EasyChannel.AbiOrdinante,
                CodiceSIAAzienda: this.config.tokenizer.SIA.EasyChannel.CodiceSIAAzienda,
                AliasPAN: this.alias_pan,
                TipoOperazione: this.config.tokenizer.SIA.EasyChannel.TipoOperazione,
                startDate: this.ECdates.startDate,
                endDate: this.ECdates.endDate
            }

            try {
                const response = await this.$http.post(`${this.apiPath}/easychannel/request`, {
                    ENDPOINT: this.config.tokenizer.SIA.EasyChannel.ENDPOINT,
                    EasyChannelOptions
                })
                const EasyChannelRisposta = response.data.EasyChannelRisposta
                if (EasyChannelRisposta && EasyChannelRisposta.ListaTariffe) {
                    const ListaTariffe = EasyChannelRisposta.ListaTariffe
                    if (ListaTariffe && ListaTariffe.Tariffa) {
                        const Tariffa = ListaTariffe.Tariffa
                        total_pending_payments_amount = Number(Tariffa.$.Importo)
                        // for (let i = 0; i < Tariffa.length; i++) {
                        //     const fare = Tariffa[i]
                        //     total_pending_payments_amount += Number(fare.$.Importo)
                        // }
                    }
                }
                return total_pending_payments_amount
            } catch (err) {
                this.setErrorFromServer(err.message)
            }
        },
        PendingPaymentProcessing() {
            localStorage.setItem('pending_payment_process', this.short_id)
        }
    },
    async mounted() {
        this.SIARequest = await this.getSIARequest()

        // Response request is DONE
        // https://pay-and-go.malpensaexpress.it/dashboard/travels/payments?ORDERID=b792e399-63b7-44c7-8595-add3038e77c4&SHOPID=09509A6WY100012&AUTHNUMBER=796087&AMOUNT=1300&CURRENCY=978&TRANSACTIONID=8032109509SL14305d9x650t8&ACCOUNTINGMODE=I&AUTHORMODE=I&RESULT=00&TRANSACTIONTYPE=TT06&PANTAIL=9412&PANEXPIRYDATE=2707&NETWORK=01&MAC=722c814ac4cea51781e5c4e2f3f43e50182417e9665e104d167700b540d5a6af
    }
}
</script>

<style lang="scss" scoped>
</style>