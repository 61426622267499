<template>
    <div class="d-flex flex-wrap">
        <div class="col-12 bg-warning p-4 rounded-4" v-if="total_pending_payments_amount !== null && total_pending_payments_amount !== 0">
            <h3 class="fw-bold mb-2">
                {{ $t('Dashboard.Payments.Modals.PendingPayments.pending') }}
            </h3>
            <h5>
                {{ $t('Dashboard.Payments.Modals.PendingPayments.online_payments') }}
            </h5>
        </div>
        <div class="col-12">
            <DataTable :date="selectedDate" :ECdates="ECdates" :tbody="payments" @filterByDate="filterByDate" @getDetails="details = $event" @getReceipt="getReceipt" v-if="!loader && payments"/>
        </div>
    </div>
    <transition name="fade-modal">
        <modal v-if="details" @close="details = null">
            <template v-slot:body>
                <div class="d-flex justify-content-between align-items-center flex-wrap p-4 border rounded-4 shadow-lg bg-white">
                    <div class="col-12">
                        <h1 class="text-uppercase text-third">
                            {{ details.Payments.short_id }}
                        </h1>
                    </div>
                    <div class="col-12 d-flex align-items-center flex-wrap my-4">
                        <img :src="require(`@/assets/img/card_schemes/${this.$formatLabel(details.BankCard.label)}.svg`)" class="card-scheme">
                        <h3 class="text-second">
                            {{ details.BankCard.truncated_pan }}
                        </h3>
                        <i class="fa-solid fa-mobile-screen-button fs-2 ms-2 text-third" v-if="details.BankCard.form_factors_name === 'DEVICE'"></i>
                    </div>
                    <div class="col-12 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Payments.Modals.PaymentDetails.token') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ details.BankCard.token }}
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Payments.Modals.PaymentDetails.amount') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ this.$formatAmount(details.Payments.amount, details.Payments.currencies_code)}}
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Payments.Modals.PaymentDetails.status') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ details.LastCharge.charge_results_name }}
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Payments.Modals.PaymentDetails.created') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ this.$formatDate(details.Payments.created, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone) }}
                        </h5>
                    </div>
                    <div class="col-12 col-sm-5 mb-4">
                        <span class="text-uppercase">
                            {{ $t('Dashboard.Payments.Modals.PaymentDetails.updated') }}
                        </span>
                        <h5 class="text-third text-break">
                            {{ this.$formatDate(details.Payments.updated, this.account.locales_id, Intl.DateTimeFormat().resolvedOptions().timeZone) }}
                        </h5>
                    </div>
                    <div class="col-12 d-flex justify-content-between align-items-center flex-wrap">
                        <div class="col-12 col-sm-5">
                            <span class="btn btn-auth btn-auth-second w-100" @click="details = null">
                                {{ $t('Dashboard.Payments.Modals.PaymentDetails.close_details') }}
                            </span>
                        </div>
                        <div class="col-12 col-sm-5" v-if="details.LastCharge.charge_results_name == 'SUCCEEDED'">
                            <span class="btn btn-auth btn-pulse text-uppercase w-100" @click="getReceipt(details.Payments.short_id)">
                                {{ $t('Dashboard.Payments.Modals.PaymentDetails.view_receipt') }}
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </modal>
    </transition>
    <transition name="fade-modal">
        <modal v-if="receipt" @close="receipt = null">
            <template v-slot:body>
                <PaymentReceipt :receipt="receipt" @downloadReceipt="downloadReceipt" @close="receipt = null"/>
            </template>
        </modal>
    </transition>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import html2pdf from "html2pdf.js"
import Modal from '@/components/Modal.vue'
import DataTable from "../../../../components/Dashboard/Travels/DataTables/PaymentsDataTable.vue"
import PaymentReceipt from '../../../../components/Dashboard/Travels/Modals/PaymentReceipt.vue'
export default {
    name: 'PaymentsView',
    components: {
        DataTable,
        Modal,
        PaymentReceipt,
    },
    computed: {
        ...mapState(['loader', 'account']),
        ...mapGetters(['apiPath', 'omsApiPath', 'omsApiKey'])
    },
    data() {
        return {
            payments: null,
            total_pending_payments_amount: null,
            selectedDate: null,
            ECdates: null,
            succeeded: true,
            failed: true,
            details: null,
            receipt: null
        }
    },
    methods: {
        ...mapMutations(['setLoader', 'setErrorFromServer']),
        getPayments(when, succeeded, failed) {
            this.setLoader(true)
            if (when === null) {
                when = {
                    ECstartDate: moment().subtract(29, 'days').format('YYYY-MM-DD-HH:mm'),
                    ECendDate: moment().format('YYYY-MM-DD-HH:mm'),
                    startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                }
            }
            this.$http
                .post(`${this.apiPath}/bank_cards/get`, { accounts_id: this.account.id }, {
                    headers: {
                        'x-api-key': this.omsApiKey
                    }
                })
                .then((res) => {
                    var bank_cards = res.data
                    var AliasPanPath = ''
                    for (var i = 0; i < bank_cards.length; i++) {
                        var bank_card = bank_cards[i]
                        AliasPanPath += `${bank_card.alias_pan}/`
                    }
                    this.$http
                        .get(`${this.omsApiPath}/payments/get/${this.omsApiKey}/0/0/4:desc/${succeeded}/${failed}/${when.startDate}/${when.endDate}/${AliasPanPath}`)
                        .then((res) => {
                            var payments = res.data.payload
                            this.getPendingPayments(AliasPanPath)
                            this.payments = payments
                            this.ECdates = {
                                startDate: when.ECstartDate,
                                endDate: when.ECendDate
                            }
                            this.selectedDate = `${moment(when.startDate).format('DD/MM/YYYY')} - ${moment(when.endDate).format('DD/MM/YYYY')}`
                            this.setLoader(false)
                        })
                        .catch((err) => {
                            this.setErrorFromServer(err.message)
                            this.setLoader(false)
                        })
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        getPendingPayments(AliasPanPath) {
            this.$http
                .get(`${this.omsApiPath}/payments/get_failed_summaries/${this.omsApiKey}/0/0/${AliasPanPath}`)
                .then((res) => {
                    var pendingPayments = res.data.payload
                    var total_pending_payments_amount = 0
                    for (let i = 0; i < pendingPayments.length; i++) {
                        const payment = pendingPayments[i]
                        total_pending_payments_amount += payment.total_amount
                    }
                    this.total_pending_payments_amount = total_pending_payments_amount
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        updateDuePayments(params) {
            var code = params.RESULT;
            var result = false
            // var reason = null
            var short_id = localStorage.getItem('pending_payment_process')
            switch (code) {
                case '00':
                    // reason = 'Success'
                    result = true
                    break;
                case '01':
                    // reason = 'Denied by the system'
                    break;
                case '02':
                    // reason = 'Denied due to store configuration issues'
                    break;
                case '03':
                    // reason = 'Denied due to communication issues with the authorization'
                    break;
                case '04':
                    // reason = 'Denied by card issuer'
                    break;
                case '05':
                    // reason = 'Denied due to incorrect card number'
                    break;
                case '06':
                    // reason = 'Unforeseen error during processing of request'
                    break;
                case '07':
                    // reason = 'Duplicated order'
                    break;
                default:
                    // reason = 'Unknown'
            }
            
            this.$http
                .get(`${this.omsApiPath}/payments/save/${this.omsApiKey}/NEXI VPOS/${params.TRANSACTIONID}/${params.ORDERID}/${params.CURRENCY}/${params.AMOUNT}/${result}//${short_id}`)
                .then((res) => {
                    if (res.data.result === true) {
                        localStorage.removeItem('pending_payment_process')
                        this.$toast.success(this.$t('toast.payment_succeeded'))
                        return
                    }
                    this.$toast.error(this.$t('ErrorFromServer'))
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                    this.setLoader(false)
                })
        },
        filterByDate(when) {
            this.payments = null
            this.getPayments(when, this.succeeded, this.failed)
        },
        getReceipt(short_id) {
            this.$http
                .post(`${this.omsApiPath}/payments/get_info`, {short_id}, {
                    headers: {
                        "api-key": this.omsApiKey
                    }
                })
                .then((res) => {
                    var info = res.data
                    info.bank_card.label = this.$formatLabel(info.bank_card.label)
                    info.payment.amount = this.$formatAmount(info.payment.amount, info.payment.currencies_code)
                    for (var i = 0; i < info.tickets.length; i++) {
                        var ticket = info.tickets[i]
                        ticket.valid_from = this.$formatDate(ticket.valid_from, this.account.locales_id, ticket.time_zone)
                        ticket.valid_to = this.$formatDate(ticket.valid_to, this.account.locales_id, ticket.time_zone)
                        ticket.ticket_amount = this.$formatAmount(ticket.ticket_amount, info.payment.currencies_code)
                        
                        if (ticket.first_validation_transit_data) {
                            var first_validation_transit_data = []
                            for (const [key, val] of Object.entries(ticket.first_validation_transit_data)) {
                                first_validation_transit_data.push({key, val})
                            }
                            ticket.first_validation_transit_data = first_validation_transit_data
                        }

                        if (ticket.last_validation_transit_data) {
                            var last_validation_transit_data = []
                            for (const [key, val] of Object.entries(ticket.last_validation_transit_data)) {
                                last_validation_transit_data.push({key, val})
                            }
                            ticket.last_validation_transit_data = last_validation_transit_data
                        }
                    }
                    this.receipt = info
                })
                .catch((err) => {
                    this.setErrorFromServer(err.message)
                })
        },
        setupPdf() {
            return new Promise((resolve) => {
                html2pdf(document.getElementById('receipt'), {
                    margin: 1,
                    filename: `${this.$t('Dashboard.Payments.Modals.PaymentReceipt.labels.receipt')}-${this.receipt.payment.short_id}.pdf`
                })
                resolve()
            })
            
        }, 
        downloadReceipt() {
            this.setupPdf()
        }
    },
    created() {
        this.getPayments(null, this.succeeded, this.failed)
        if (Object.keys(this.$route.query).length !== 0) {
            this.updateDuePayments(this.$route.query)
        }
    }
}
</script>