<template>
    <div class="d-flex justify-content-center flex-wrap">
        <div class="col-12 col-xl-8 border rounded-4 shadow-lg">
            <SIATokenizer/>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import SIATokenizer from '@/components/SIATokenizer.vue'
export default {
    name: 'AddBankCards',
    components : {
        SIATokenizer
    },
    computed: {
        ...mapState(['account']),
        ...mapGetters(['apiPath', 'omsApiPath', 'omsApiKey'])
    },
    methods: {
        ...mapMutations(['setErrorFromServer']),
        async decoded_res(SIA_res) {
            var short_id = null
            var pan_short_id = null
            var payment_account_reference = null
            
            if (SIA_res) {
                pan_short_id = SIA_res
            }
            
            try {
                const response = await this.$http.get(`${this.omsApiPath}/bank_cards/get/${this.omsApiKey}///${short_id}/${pan_short_id}/${payment_account_reference}`)
                if (response.data.payload.length > 0) {
                    try {
                        const res = await this.$http.post(`${this.apiPath}/bank_cards/add`, { 
                            bank_card: response.data.payload[0],
                            accounts_id: this.account.id
                        }, { 
                            headers: {
                                'x-api-key': this.omsApiKey
                            }
                        })
                        switch (res.data) {
                            case 'Bank Card Already Added':
                                this.$toast.info(`<h3>${this.$t('Dashboard.AddBankCards.Modals.tokenizer_response.already_added')}</h3> <h5>${response.data.payload[0].truncated_pan}</h5>`)
                                break
                            default:
                                this.$toast.success(`<h3>${this.$t('Dashboard.AddBankCards.Modals.tokenizer_response.succeeded')}</h3> <h5>${response.data.payload[0].truncated_pan}</h5>`)
                                break
                        }
                    } catch (err) {
                        this.setErrorFromServer(err.message)
                    }
                } else {
                    this.$toast.info(`<h4>${this.$t('Dashboard.AddBankCards.Modals.tokenizer_response.unused')}</h4>`)
                }
                this.$router.replace('/dashboard/travels/bank_cards')
            } catch (err) {
                this.setErrorFromServer(err.message)
            }
        }
    },
    async mounted() {
        if (Object.keys(this.$route.query).length !== 0) {
            await this.decoded_res(this.$route.query.PANALIAS)
        }
    }
}
</script>